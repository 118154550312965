<!--
 * @Author: CL
 * @Date: 2021-06-23 09:42:29
 * @LastEditTime: 2021-06-24 18:45:48
 * @Description: 回到首页的组件
-->

<template>
  <div class="gohome-contain" @click="handleGoHome">
    磊
  </div>
</template>

<script>

export default {
  data(){
    return {
      
    }
  },

  methods: {
    /**
     * 跳转到首页
     */
    handleGoHome(){
      this.$router.push({
        name: 'home'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.gohome-contain{
  position: fixed;
  font-weight: bold;
  font-size: 30px;
  top: 15px;
  left: 25px;
  cursor: pointer;

  background-image: -webkit-linear-gradient(left,#27ae60,#2980b9,#0080c0,#E6a23c);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-background-size: 200% 100%;
  -webkit-animation: move 10s infinite linear;

  // .text-animation();
}

@keyframes move {
  0%  { background-position: 0 0;}
  100% { background-position: -100% 0;}
}
</style>
