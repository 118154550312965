<!--
 * @Author: CL
 * @Date: 2021-06-18 15:45:31
 * @LastEditTime: 2021-06-28 15:48:35
 * @Description: 滚动到顶部的组件
 1. isShow: Boolean    是否展示
-->

<template>
  <div v-show="isShow" class="totop-contain" @click="handleClick">top</div>
</template>

<script>
export default {
  methods: {
    handleClick(){
      this.$bus.$emit('scrollTop');
    }
  },

  props: {
    isShow: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.totop-contain{
  position: fixed;
  z-index: 99;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: @primary;
  border: 2px solid @menutext;
  cursor: pointer;
  transition: all 1s;
}
</style>
