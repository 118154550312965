<!--
 * @Author: CL
 * @Date: 2021-06-22 10:41:15
 * @LastEditTime: 2021-07-05 10:52:23
 * @Description: 留言
-->

<template>
  <div class="message-contain" ref="message" @scroll="handleScroll">
    <Message @submit="handleSubmit" />
    <MessageList title="留言板" :subTitle="total" :list="list" v-loading="loading" />
    <LoadingMore :isShow="isLoadingMore" :bottom="20" />
    <NoMore :isShow="hasMore" word="留言" />
    <GoHome />
    <!-- <ToTop :isShow="isShow" /> -->
    <Rocket :isLaunch="isShow" :isLand="isLand" />
  </div>
</template>

<script>
import Message from '@/components/Comments';
import MessageList from './components/messageList';
import { queryMes, addMessage } from '@/api/message.js';
import GoHome from '@/components/GoHome';
import ToTop from '@/components/ToTop';
import LoadingMore from '@/components/LoadingMore';
import NoMore from '@/components/NoMore';
import Rocket from '@/components/Rocket';

export default {
  data(){
    return {
      loading: true,
      page: 1,
      size: 10,
      total: 0,
      list: [],
      isShow: false,
      isLand: false,
      isLoadingMore: false,
      hasMore: false
    }
  },

  components: {
    Message,
    MessageList,
    GoHome,
    // ToTop,
    LoadingMore,
    NoMore,
    Rocket
  },

  methods: {
    /**
     * 分页查询留言数据
     */
    async queryMessage(){
      this.loading = true;
      try{
        const { page, size } = this;
        const res = await queryMes(page, size);
        this.loading = false;
        if(res.code == 200){
          const { count, rows } = res.data;
          this.total = count;
          this.list = rows;
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 加载更多的数据
     */
    async fetchMore(){
      //如果已经没有数据了，什么都不做
      if(this.total == this.list.length) {
        this.hasMore = true;
        return;
      };
      this.isLoadingMore = true;
      //还有数据
      this.page++;
      try{
        const res = await queryMes(this.page, this.size);
        await this.$utils.delay(1500);
        this.isLoadingMore = false;
        if(res.code == 200){
          this.list = [...this.list, ...res.data.rows];
        } 
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 表单组件emit出来的方法
     */
    async handleSubmit(data = {}, callback){
      try{
        const res = await addMessage(data);
        if(res.code == 200){
          this.list.unshift(res.data);
          this.total++;
          callback('留言成功'); 
        }
      }catch(err){
        console.log(err);
      }
    },

    /**
     * 滚动事件
     */
    handleScroll(){
      // console.log('这个div的高度', this.$refs.message.clientHeight);
      // console.log('滚动的高度', this.$refs.message.scrollHeight);
      // console.log('滚动的距离', this.$refs.message.scrollTop);
      if(this.$refs.message.scrollTop == 0){
        this.isShow = true;
        this.isLand = false;
      }else if(this.$refs.message.scrollTop > 0){
        this.isLand = true;
      }
      
      if(this.$refs.message.scrollTop + this.$refs.message.clientHeight >= this.$refs.message.scrollHeight - 50){
        this.fetchMore();
      }
    },

    handleScrollTop(){
      this.$refs.message.scrollTop = 0;
      setTimeout(() => {
        this.isShow = true;
        this.isLand = false;
      }, 500)
    }
  },

  created(){
    this.queryMessage();
  },

  mounted(){
    this.$bus.$on('scrollTop', this.handleScrollTop);
  },

  destroyed() {
    this.$bus.$off('scrollTop', this.handleScrollTop);
  },
}
</script>

<style lang="less" scoped>
.message-contain{
  position: relative;
  height: 100%;
  padding: 20px 250px;
  box-sizing: border-box;
  overflow-y: scroll;
  scroll-behavior: smooth;

  .nomore-contain{
    padding-bottom: 10px;
  }
}
</style>
