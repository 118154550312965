<!--
 * @Author: CL
 * @Date: 2021-06-27 21:31:51
 * @LastEditTime: 2021-06-28 09:29:15
 * @Description: 留言列表组件
 * 1. title:String  主标题
 * 2. subTitle:String   副标题
 * 3. list:Array    评论数据
-->

<template>
  <div class="messagelist-contain">
    <div class="comment-title">{{ title }}({{ subTitle }})</div>
    <div class="list">
      <div class="comment-item" v-for="item in list" :key="item.id">
        <Avatar :url="baseURL + item.avatar" />
        <div class="item-right">
          <div class="top">
            <div>{{ item.nickName }}</div>
            <div>{{ item.createdAt }}</div>
          </div>
          <div class="main">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import { mapState } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      required: true
    },

    subTitle: {
      type: (Number || String),
      required: true
    },

    list: {
      type: Array,
      required: true
    }
  },

  components: {
    Avatar
  },

  computed: {
    ...mapState(['baseURL'])
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.messagelist-contain{
  margin-top: 30px;
  padding-bottom: 30px;

  .comment-title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;  
    box-sizing: border-box;
  }

  .comment-item{
    display: flex;
    width: 100%;
    border-bottom: 1px solid @borderBottom;
    padding: 15px 0 15px 0;
    margin: 20px 0;

    .item-right{
      width: 100%;
      margin-left: 20px;
        
      .top{
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div:nth-of-type(1){
          font-size: 18px;
          color: @primary;
        }

        > div:nth-of-type(2){
          font-size: 12px;
          color: @success;
        }
      }

      .main{
        margin-top: 10px;
      }
    }
  }
}
</style>
