/*
 * @Author: CL
 * @Date: 2021-06-27 21:48:09
 * @LastEditTime: 2021-07-22 11:36:51
 * @Description: 留言相关的api
 */

import request from './request';

/**
 * 分页查询留言数据
 * @param {*} page 
 * @param {*} size 
 * @returns 
 */
export function queryMes(page = 1, size = 10) {
  return request({
    url: `/api/message/${page}/${size}`,
    method: 'get'
  })
}

/**
 * 添加留言
 * @param {*} data 
 * @returns 
 */
export function addMessage(data = {}) {
  return request({
    url: '/api/message',
    method: 'post',
    data
  })
}

/**
 * 删除留言
 */
export function delMessage(id) {
  return request({
    url: `/api/message/${id}`,
    method: 'delete'
  })
}
