<!--
 * @Author: CL
 * @Date: 2021-06-30 10:05:29
 * @LastEditTime: 2021-07-01 23:02:40
 * @Description: 没有更多的组件
-->

<template>
  <div class="nomore-contain" v-if="isShow">
    没有更多{{ word }}了哦！(*╹▽╹*)
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true
    },

    word: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.nomore-contain{
  text-align: center;
  color: @menutext;
  animation: show 1s forwards;
}

@keyframes show{
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}
</style>
