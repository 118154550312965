<!--
 * @Author: CL
 * @Date: 2021-06-16 13:57:38
 * @LastEditTime: 2021-06-28 15:54:00
 * @Description: 评论组件

 * 5. sumbit:  emit的事件表单提交
-->

<template>
  <div class="comments-contain">
    <div class="from-wrap" ref="form">
      <div class="form-item-name">
        <input 
          class="input-name" 
          type="text" 
          name="name" 
          v-model="ruleForm.nickName" 
          autocomplete="off"
          placeholder="输入您的昵称"
          maxlength="10"
        >
        <span>{{nameCount}}/10</span>
      </div>
      <div class="error-info">{{ error.name }}</div>
      <div class="form-item-area">
        <textarea 
          name="content" 
          class="input-content"
          v-model="ruleForm.content"
          placeholder="输入您的评论内容"
        ></textarea>
        <span>{{contentCount}}/300</span>
      </div>
      <div class="error-info">{{ error.content }}</div>
      <div class="submit" :class="{disabled: isSubmiting}" @click="handleSubmit">{{ isSubmiting? '提交中...' : '提交' }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data(){
    return {
      ruleForm: {
        nickName: '',         //昵称
        content: '',      //内容
      },

      error: {
        name: '',
        content: ''
      },

      isSubmiting: false
    }
  },

  methods: {
    /**
     * 表单提交事件
     */
    handleSubmit(){
      if(this.isSubmiting) return;
      //表单验证
      this.error.name = this.ruleForm.nickName? '' : '请输入您的昵称';
      this.error.content = this.ruleForm.content? '' : '请输入您的内容';
      if(this.error.name || this.error.content) return;
      this.isSubmiting = true;
      this.$emit('submit', this.ruleForm, (msg) => {
        this.$showMessage({
          message: msg,
          type: 'success',
          dom: this.$refs['form']
        });
        this.isSubmiting = false;
        this.ruleForm.nickName = '';
        this.ruleForm.content = '';
      })
    }
  },

  computed: {
    /**
     * 昵称的字数
     */
    nameCount(){
      if(this.ruleForm['nickName'] == '' || this.ruleForm['nickName'] == null) return 0;
      // return getStringLength(this.ruleForm['nickName']);
      return this.ruleForm['nickName'].length;
    },

    /**
     * 内容的字数
     */
    contentCount(){
      if(this.ruleForm['content'] == '' || this.ruleForm['content'] == null) return 0;
      return this.ruleForm['content'].length;
    }
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.comments-contain{
  width: 100%;
  padding: 30px 0 0 0;

  .form-item-name{
    width: 400px;
    height: 40px;
    position: relative;

    span{
      display: inline-block;
      position: absolute;
      right: 5px;
      bottom: 5px;
      font-size: 12px;
      color: @menutext;
    }

    > input{
      border: 2px dotted @info;
      border-radius: 5px;
      padding: 0 10px;
      box-sizing: border-box;
      outline: none;
      transition: all 1s;
      font-size: 1em;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;


      &.input-name{
        width: 400px;
        height: 40px;
        margin-bottom: 30px;
      }

      &:focus{
        border: 2px dotted @bluedark;
      }
    }
  }

  .form-item-area{
    margin-top: 30px;
    position: relative;
    
    span{
      display: inline-block;
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 12px;
      color: @menutext;
    }
    
    > textarea{
      border: 2px dotted @info;
      border-radius: 5px;
      padding: 0 10px;
      box-sizing: border-box;
      outline: none;
      transition: all 1s;
      font-size: 18px;
      resize: none;
      color: #373737;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      
      &.input-content{
        width: 100%;
        height: 100px;
        font-size: 1em;
        padding: 10px;
      }

      &:focus{
        border: 2px dotted @bluedark;
      }
    }
  }

  .submit{
    width: 100px;
    height: 38px;
    border-radius: 5px;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    color: #fff;
    margin-top: 20px;
    position: relative;
    background-image:linear-gradient(125deg,#2c3e50,#27ae60,#2980b9,#0080c0, #8e44ad, orange);
    background-size: 400%;   
    animation: move 8s infinite;

    &.disabled{
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .error-info{
    font-size: 14px;
    color: @danger;
  }
}

@keyframes move {
	0% {
    background-position: 0% 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
