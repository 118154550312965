<!--
 * @Author: CL
 * @Date: 2021-06-01 23:51:18
 * @LastEditTime: 2021-06-27 23:05:33
 * @Description: 头像组件
 * 1. url: 传进来的图片的路径, 必传
 * 2. size: 传进来的图片的尺寸, 默认宽度和高度相同, 有默认值可不传
-->

<template>
  <img class="avatar-img avatar-contain" :src="url" :style="{ width: size + 'px', height: size + 'px' }">
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },

    size: {
      type: Number,
      default: 50
    }
  },
}
</script>

<style scoped>
.avatar-img{
  display: block;
  border-radius: 50%;
  object-fit: cover;
}
</style>
