<!--
 * @Author: CL
 * @Date: 2021-07-02 16:45:06
 * @LastEditTime: 2021-07-04 22:47:00
 * @Description: 火箭组件
-->

<template>
  <div class="rocket-contain" :class="{ 'launch': isLaunch, 'land': isLand }" @click="launch">
    <div class="rockets">
      <div class="rockets-body"></div>
      <div class="rockets-windows">
        <span></span> 
        <span></span>
      </div>
      <div class="rockets-bottom">
        <span></span> 
        <span></span> 
        <span></span>
      </div>
      <div class="fire burn">
        <span></span>
        <span></span>
        <span></span>
        <div class="glow"></div>
      </div>
    </div>
    <div class="shadow"></div>
  </div>
</template>

<script>
export default {
  props: {
    isLaunch: {
      type: Boolean,
      default: false
    },

    isLand: {
      type: Boolean,
      default: false,
    }
  },
  
  methods: {
    launch(){
      this.$bus.$emit('scrollTop');
    }
  }
}
</script>

<style lang="less" scoped>
.rocket-contain{
  position: fixed;
  width: 200px;
  height: 150px;
  bottom: 120%;
  right: 10px;
  cursor: pointer;

  /* 火箭发射样式 */
  &.launch {
    animation: launch 3s ease forwards;
  }
      
    @keyframes launch {
      0% {
        bottom: 120px;
        transform: translatex(2px);
      }
      10% {
        transform: translatex(-2px);
      }
      20% {
        transform: translatex(2px);
      }
      30% {
        transform: translatex(-2px);
      }
      40% {
        transform: translatex(0px);
        bottom: 150px;
      }
      100% {
        bottom: 120%;
      }
    }

    /* 火箭着陆 */
    &.land {
      animation: land 3s ease forwards;
    }
      
    @keyframes land {
      0% {
        bottom: 120%;
      }
      100% {
        bottom: 120px;
      }
    }
  
  .rockets{
    width: 200px;
    height: 150px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 2s ease;
    animation: float 2s ease infinite alternate;

    // 火箭身体
    .rockets-body{
      width: 35%;
      height: 80%;
      background: #fed;
      border-bottom-left-radius: 100%;
      border-bottom-right-radius: 100%;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;
      box-shadow: inset 0px -124px 0px -80px white, inset 0px -140px 0px -60px #4ba3b7; 
    }

    .rockets-body:before {
      content: "";
      position: absolute;
      width: calc(100% - 36px);
      height: 42%;
      background-color: inherit;
      bottom: -30px;
      transform: perspective(10em) rotateX(-50deg);
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      box-shadow: inset 0px -33px 0px 0px rgba(0, 0, 0, 0.1);
    }

    .rockets-body:after {
      content: "";
      position: absolute;
      width: 45%;
      height: 40px;
      background-color: #f95959;
      bottom: -30px;
      transform: perspective(10em) rotateX(-50deg);
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      z-index: -1;
    }

    // 火箭窗口
    .rockets-windows{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 300px;
      position: absolute;
    }

    .rockets-windows span {
      background-color: #ace7ef;
      box-shadow: inset -4px 4px 0px 0px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 2px white;
      border: 4px solid #f95959;
      z-index: 2;
      position: absolute;
      border-radius: 100%;
      overflow: hidden;
    }

    .rockets-windows span:before {
      position: absolute;
      content: "";
      background-color: white;
      width: 200%;
      height: 100%;
      transform: rotate(45deg);
      opacity: 0.4;
    }
      
    .rockets-windows span:nth-child(1) {
      width: 18px;
      height: 18px;
      top: 33%;
    }
      
    .rockets-windows span:nth-child(1):before {
      top: 10px;
      right: 0px;
    }

    .rockets-windows span:nth-child(2) {
      width: 26px;
      height: 26px;
      top: 44%;
    }

    .rockets-windows span:nth-child(2):before {
      top: 12px;
      right: 0px;
    }

    
    // 火箭底部部分
    .rockets-bottom{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 180px;
      position: absolute;
    }

    .rockets-bottom span {
      background-color: #4ba3b7;
      border-radius: 10px;
      position: absolute;
      overflow: hidden;
    }
      
    .rockets-bottom span:before {
      content: "";
      position: absolute;
      background-color: white;
      width: 2px;
      height: 120%;
      border-radius: 20px;
    }
    
    .rockets-bottom span:nth-child(1) {
      width: 15px;
      height: 62px;
      z-index: 2;
      bottom: 2%;
      box-shadow: inset -5px -3px 0px 0px rgba(0, 0, 0, 0.18);
    }
      
    .rockets-bottom span:nth-child(1):before {
      display: none;
    }
      
    .rockets-bottom span:nth-child(2) {
      width: 50px;
      height: 130px;
      left: 32%;
      bottom: 6%;
      transform: perspective(10em) rotateX(60deg) translateZ(-1px);
      box-shadow: inset -5px -3px 0px 0px rgba(0, 0, 0, 0.2);
    }

    .rockets-bottom span:nth-child(2):before {
      left: 0px;
      border-right: 2px solid #f95959;
    }
      
    .rockets-bottom span:nth-child(3) {
      width: 50px;
      height: 130px;
      right: 32%;
      bottom: 6%;
      transform: perspective(10em) rotateX(60deg) translateZ(-1px);
      box-shadow: inset -5px -3px 0px 0px rgba(0, 0, 0, 0.2);
    }
      
    .rockets-bottom span:nth-child(3):before {
      right: 0px;
      border-left: 2px solid #f95959;
    }

    // 点火
    .fire{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 300px;
      height: 100px;
      position: absolute;
      bottom: -50px;
    }

    .fire.burn span {
      border-radius: 50px;
      top: 0;
      position: absolute;
      background-color: #ffd460;
      height: inherit;
      animation: fire 0.8s ease infinite alternate;
    }

    .fire.burn span:nth-child(1) {
      width: 6px;
      height: 40px;
      left: 46%;
      transform: translateY(27px);
      box-shadow: inset 0px -7px 10px #ea5455, inset 0px -19px 10px #ffc175, 0px -7px 10px #ea5455;
      animation-delay: 0.2s;
    }

    .fire.burn span:nth-child(1):after {
      position: absolute;
      content: "";
      width: 4px;
      height: 60%;
      border-radius: 50px;
      background-color: #ffd460;
      bottom: 0;
      transform: translate(8px, 15px);
      box-shadow: inset 0px -5px 10px #ea5455, inset 0px -19px 10px #ffc175, 0px -7px 10px #ea5455;
    }

    .fire.burn span:nth-child(2) {
      width: 10px;
      height: 60px;
      left: calc(50% - 8px);
      transform: translateY(35px);
      box-shadow: inset 0px -10px 10px #ea5455, inset 0px -30px 10px #ffc175, 0px -7px 10px #ea5455;
    }
      
    .fire.burn span:nth-child(2):after {
      position: absolute;
      content: "";
      width: 10px;
      height: 100%;
      border-radius: 10px;
      background-color: #ffd460;
      top: 0;
      transform: translate(-6px, -25px);
      box-shadow: inset 0px -5px 10px #ea5455, inset 0px -15px 10px #ffc175, 0px -7px 10px #ea5455;
    } 

    .fire.burn span:nth-child(3) {
      width: 10px;
      height: 40px;
      right: 45%;
      transform: translateY(27px);
      box-shadow: inset 0px -5px 10px #ea5455, inset 0px -30px 10px #ffc175, 0px -7px 10px #ea5455;
      animation-delay: 0.4s;
    }

    .fire.burn span:nth-child(3):after {
      position: absolute;
      content: "";
      width: 6px;
      height: 180%;
      border-radius: 10px;
      background-color: #ffd460;
      top: 0;
      transform: translate(-6px, -15px);
      box-shadow: inset 0px -5px 10px #ea5455, inset 0px -20px 10px #ffc175, 0px -7px 10px #ea5455;
    }

    @keyframes fire {
      0% {
        height: 10px;
        bottom: 0;
        50% {
          top: 0;
        }
        100% {
          height: 20px;
          bottom: 0;
        }
      }
    }

    .fire.burn > .glow {
      position: absolute;
      width: 0px;
      height: 0px;
      border-radius: 100%;
      box-shadow: 0px 0px 50px 20px #ea5455;
      opacity: 1;
      animation: glow 0.8s ease infinite alternate;
    }

    @keyframes glow {
      0% {
        box-shadow: 0px 0px 50px 20px #ea5455;
      }
        
      100% {
        box-shadow: 0px 0px 50px 25px #ea5455;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}
</style>
