<!--
 * @Author: CL
 * @Date: 2021-06-28 16:50:24
 * @LastEditTime: 2021-06-29 10:50:24
 * @Description: 加载更多的组件
-->

<template>
  <div class="loadingmore-contain" v-show="isShow" :style="{ paddingBottom: bottom + 'px' }">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      required: true
    },

    bottom: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.loadingmore-contain{
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0 0 0;

  > div{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: @primary;
    margin: 0 5px;
    animation: move 2s infinite;
    box-shadow: 0 0 8px 12px rgba(64,158,255,0.3);

    &:nth-of-type(2){
      background: @danger;
      box-shadow: 0 0 8px 12px rgba(245,108,108,0.3);
    }

    &:nth-of-type(3){
      background: @warning;
      box-shadow: 0 0 8px 12px rgba(230,162,60,0.3);
    }

    &:nth-of-type(4){
      background: @success;
      box-shadow: 0 0 8px 12px rgba(103,194,58,0.3);
    }

    &:nth-of-type(5){
      background: @success;
      box-shadow: 0 0 8px 12px rgba(58, 140, 194, 0.3);
    }
  }
}

@keyframes move{
  0%{
    transform: scale(1);
    opacity: 0.9;
  }

  50%{
    transform: scale(1.5);
    opacity: 0.8;
  }

  100%{
    transform: scale(1);
    opacity: 0.9;
  }
}
</style>
